import React from 'react';
import { Button } from 'antd';

export default class extends React.Component {
  
  static ButtonGroup = Button.Group;
  
  render() {
    const { children, className = 'default' } = this.props;
    return (
      <Button {...this.props} className={className}>{children}</Button>
    );
  }
}
