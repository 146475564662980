import React from 'react';
import { connect } from 'dva';
import RadioGroup from '../RadioGroup';
import Radio from '../Radio';
//
@connect()
export default class extends React.Component {
  componentDidMount() {
    const { activeFirst, enums, onChange } = this.props;
    const value = this.getValue();
    if (activeFirst && !value) {
      const enumsData = enums.getData();
      const names = Object.keys(enumsData);
      onChange(enumsData[names[0]]);
    }
  }

  getValue = () => {
    const { value } = this.props;
    return typeof value === 'number' ? `${value}` : value;
  };

  render() {
    const { enums, button = false } = this.props;
    const enumsData = enums.getData();
    const names = Object.keys(enumsData);
    const finalProps = { ...this.props };
    finalProps.value = this.getValue();
    return (
      <RadioGroup {...finalProps}>
        {names.map((name, index) => {
          const Component = button ? Radio.Button : Radio;
          return <Component key={index} value={enumsData[name]}>{name}</Component>
        })}
      </RadioGroup>
    )
  }
}
