import React from "react";
import { Cascader } from "antd";
import { getProvinces, getChildAreas, getAllParentsRegion } from "framework/services/region";

//
class RegionSelect extends React.Component {
  state = { options: [], value: [] };

  loadData = async selectedOptions => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    if (!targetOption) {
      return;
    }
    const targetLevel = selectedOptions.length;
    const { value: valueKey, children: childrenKey } = this.props.fieldNames;
    targetOption.loading = true;
    const targetValue = targetOption[valueKey];
    const childAreas = await getChildAreas(targetValue);
    const { options } = this.state;
    targetOption.loading = false;
    if (childAreas.length === 0) {
      targetOption.isLeaf = true;
    } else {
      const { maxCols = 4 } = this.props;
      targetOption[childrenKey] = childAreas.map(item => ({
        ...item,
        isLeaf: targetLevel >= maxCols - 1
      }));
    }
    this.setState({ options: [...options] });
  };

  handleChange = (value, selectedOptions) => {
    const { onChange } = this.props;
    this.setState({ value });
    onChange && onChange({ value, selectedOptions });
  };
  clearValue = () => {
    this.setState({ value: '' });
  };

  getAllAreas = (searchOptions) => {
    const { options } = this.state;
    let areas = [];
    (searchOptions || options).forEach(option => {
      areas.push(option);
      const children = this.getAllAreas(option.children || []);
      areas = areas.concat(children);
    });
    return areas;
  };

  getOptionByValue = (value) => {
    const { options } = this.state;
    const { value: valueKey, children: childrenKey } = this.props.fieldNames;
    const areas = this.getAllAreas();
    return areas.find(area => area[valueKey] == value);
  };

  init = async () => {
    const { getOptionByValue, loadData } = this;
    const { initvalue } = this.props;
    if (initvalue) {
      const value = [];
      const regions = getAllParentsRegion(initvalue);
      for (let i in regions) {
        const loadAreas = regions.filter((item, index) => (index < i));
        if (loadAreas.length > 0) {
          await loadData(loadAreas.map(area => getOptionByValue(area.areaCode)));
        }
      }
      for (let i in regions) {
        value.push(regions[i].areaCode);
      }
      this.setState({ value });
    }
  };

  async componentDidMount() {
    const provinces = await getProvinces();
    this.setState({
      options: provinces.map(item => ({ ...item, isLeaf: false }))
    });
    this.init();
  }

  componentWillReceiveProps(nextProps) {

    if (!nextProps.initvalue) {
      this.clearValue();
    }
  }

  findItem(value, options, key) {
    let item;
    for (let i = 0, nLen = options.length; i < nLen; i++) {
      item = options[i];
      if (item[key] == value) {
        return item;
      }
    }
    return;
  }

  getReadonlyDOM(options, value, fieldNames) {
    let items = options;
    let item;
    let title = "";
    for (let i = 0, nLen = value.length; i < nLen; i++) {
      item = this.findItem(value[i], items, fieldNames.value);
      if (item) {
        if (title.length > 0) {
          title += " / " + item[fieldNames.label];
        } else {
          title += item[fieldNames.label];
        }
        items = item[fieldNames.children];
      } else {
        break;
      }
    }
    return <span>{title}</span>;
  }

  render() {
    const { options, value } = this.state;
    const { placeholder, fieldNames, readOnly } = this.props;
    if (readOnly) {
      if (options && options.length > 0 && value && value.length > 0) {
        return this.getReadonlyDOM(options, value, fieldNames);
      }
    }
    const finalProps = { ...this.props };
    delete finalProps.maxCols;
    return (
      <Cascader
        {...finalProps}
        value={value}
        options={options}
        fieldNames={fieldNames}
        loadData={this.loadData}
        onChange={this.handleChange}
        changeOnSelect
        placeholder={placeholder}
      />
    );
  }
}

RegionSelect.defaultProps = {
  placeholder: "请选择",
  fieldNames: { label: "cityName", value: "areaCode", children: "children" }
};
export default RegionSelect;
