/*
 * @Author: xutengfeng 
 * @Date: 2019-08-23 14:41:00 
 * @Last Modified by: xutengfeng
 * @Last Modified time: 2019-08-23 16:15:55
 */

import React, { useRef, forwardRef, memo, useImperativeHandle, useCallback } from 'react';
import classNames from 'classnames';
import { Modal, Icon } from 'components';
import styles from './index.less';

function ImageView({ className, style, src, alt = "图片预览", width = 640 }, ref) {
  const modalRef = useRef(null);
  const handlePreview = useCallback(() => {
    modalRef.current.show();
  }, []);

  const handleOk = useCallback(() => {
    modalRef.current.hide();
  }, []);

  useImperativeHandle(ref, () => ({
    preview: handlePreview
  }));
  return (
    <div style={style} className={classNames(styles['image-preview'], className)}>
      <img className={styles['image-preview-img']} src={src} alt={alt} />
      <div className={styles['image-cover']}>
        <Icon style={{ color: '#FFFFFF' }} onClick={handlePreview} type="eye" />
      </div>
      <Modal
        width={width}
        ref={modalRef}
        footer={null}
        maskClosable
        okHandler={handleOk}
      >
        <img className={styles['modal-image']} src={src} alt={alt} />
      </Modal>
    </div>
  )
}

export default memo(forwardRef(ImageView))