import React from 'react';
import { connect } from 'dva';
import Select from '../Select';
import Option from '../Option';
//
@connect()
export default class extends React.Component {
  componentDidMount() {
    const { activeFirst, enums, onChange } = this.props;
    const value = this.getValue();
    if (activeFirst && !value) {
      const enumsData = enums.getData();
      const names = Object.keys(enumsData);
      onChange(enumsData[names[0]]);
    }
  }

  getValue = () => {
    const { value } = this.props;
    return typeof value === 'number' ? `${value}` : value;
  };

  render() {
    const { enums } = this.props;
    const enumsData = enums.getData();
    const names = Object.keys(enumsData);
    const finalProps = { ...this.props };
    finalProps.value = this.getValue();
    return (
      <Select {...finalProps}>
        {names.map((name, index) => <Option key={index} value={enumsData[name]}>{name}</Option>)}
      </Select>
    )
  }
}
