import React from 'react';
import {Upload} from 'antd';
import config from 'framework/common/config';
import api from 'framework/common/api';

const {fileDomain} = config;

class CjmUpload extends React.PureComponent {
  static getDerivedStateFromProps(nextProps) {
    if ('fileList' in nextProps) {
      return {
        ...(nextProps.fileList || {}),
      };
    }
    return {fileList: []};
  }

  state = {name: '', fileList: []};
  handleBeforeUpload = (file) => {
    const {name} = file;
    this.setState({name});
  };
  handleChange = ({file, fileList}) => {
    const {status} = file;
    let files = [];
    let names = [];
    if (status == 'done' && file.response.state == 200) {
      files = fileList.map((item) => {
        return item.fileId || item.response.results;
      });
    }
    if (files.length === 0) {
      this.setState({fileList})
    } else {
      this.setState({fileList: []})
    }
    if(Array.isArray(fileList)){
      names = fileList.map(item => item.name);
    }
    this.triggerChange(files, names);
  };

  triggerChange = (files, names) => {
    const {onChange} = this.props;
    if (onChange) {
      const value = files.length == 1 ? files.toString() : files;
      onChange(value, names);
    }
  };
  getFileList = () => {
    if (this.state.fileList.length > 0) {
      return this.state.fileList;
    }
    const { fileList, urlFormat, nameFormat } = this.props;
    return fileList.map((item, index) => {
      const url = urlFormat ? urlFormat(item) : `${fileDomain}/${item.name}`;
      const fileName = nameFormat ? nameFormat(item, index) : `附件${index + 1}`;
      const file = { url, status: 'done', uid: item.name, fileId: item.name, name: fileName };
      return file;
    })
  }

  render() {
    const {name} = this.props;
    return (
      <Upload
{...this.props}
        fileList={this.getFileList()}
        data={{name}}
        beforeUpload={this.handleBeforeUpload}
        onChange={this.handleChange}
      >{this.props.children}
      </Upload>
    )
  }
}
CjmUpload.defaultProps = {
  action: api.FILE_UPLOAD,
};
export default CjmUpload;
