export default (() => {
  const host = location.origin.replace(`:${location.port}`, "");
  return {
    //
    domain: location.hostname,
    // 企业中心地址
    orgSite: `${host}:7071`,
    // 用户中心地址
    userCenterSite: `${host}:7070`,
    // 用户中心登录页
    userCenterLogin: `${host}:7070/#/user/login`,
    // 系统中心地址
    systemSite: `${host}:7073`,
    // 系统中心  企业着落页地址
    systemLandingPageSite: `${host}:7073/#/platform/landingPage/exhibit`,
    // 平台中心地址
    platformSite: `${host}:7072`,
    // 新用户注册
    register: `${host}:7070/#/user/register`,
    // 单点登录iframe的src
    ssoSrc: `${host}:7070/#/frame/user/login`,
    // 图片上传返回值
    fileDomain: "http://filetest.jgwcjm.com",
    // 视频文件域名
    videoDomain: "https://cjm3v.kf315.net",
    // 溯源预览，h5模板预览
    previewWarp: `${host}:7077`,
    mapjs: `http://dev-gis.kf315.net`,
    iotmapjs: "https://gis.jgwcjm.com",
    nurturePreview: `${host}:7100`,
    //贫困户预览
    previewPorverty: `${host}:3005`,
    // 溯源扫码
    traceH5: `${host}:7077/#/traceH5`,
    // 码营销H5
    integralMarketingH5Site: `${host}:7081`,
    //会员御花园
    integralShop: `${host}:7086`,
    //政府对接
    govConnectSite: `${host}:7088`,
    //iot平台
    iotSite: `${host}:7090`,
    //一体机
    aioSite: `${host}:3050`,
    // 保险理赔H5
    insuranceDomain: `${host}:3004/#/trace`,
    //数据屏地址
    monitorScreenConfigPage: `${host}:8081`,
    //润达数据屏预览地址
    monitorScreenConfigPageRunDa: "http://freedom.kf315.net/datascreen/runda/",
    monitorScreenConfigPageGugeng: "http://localhost:8085/#/",
    //微页配置工具地址
    microPageSite: "http://localhost:8586",
    //H5工具地址
    h5ToolsSite: {
      home: "http://localhost:9080/h5Tools/home/index.html", //访问地址
      preview: "http://localhost:9081/h5Tools/preview/index.html", //预览地址
      settings: "http://localhost:9082/h5Tools/settings/index.html", //配置地址
      queryResults: {
        //H5工具-查询结果页-地址
        home: "http://localhost:9080/h5Tools/home/query-results.html",
        preview: "http://localhost:9081/h5Tools/preview/query-results.html",
        settings: "http://localhost:9082/h5Tools/settings/query-results.html",
      },
    },
    //防伪模板三链接
    antiHref3: "http://antibackend.h5.kf315.net/u/r/h3",
    // 农安信用跳转链接
    creditHref: '',
    // 企业工具跳转链接
    mobileAppHref: "https://test-mobile-website-h5.jgwcjm.com",
    //数字乡村一户一码
    digitalVillageHref: `${host}:3006`,
    //会员H5
    shopH5: `${host}:7086`,
    // 防伪数据屏
    antiFakeDataScreen: "http://freedom.h5.kf315.net/datascreen/antiFake",
    // 钓鱼台数据屏
    diaoyutaiDataScreen: "http://freedom.h5.kf315.net/dataScreen/diaotai",
    // 钓鱼台防窜货数据屏
    diaoyutaiFchDataScreen:
      "http://freedom.h5.kf315.net/dataScreen/antiChannelConflict",
    // 基地监管GIS地图
    baseGISDataScreen: "http://freedom.h5.kf315.net/datascreen/kaihua",

    // 防伪
    antifakePlatform: "https://antifake.h5.kf315.net/",

    // b2b商城
    B2BShop: "https://b2b.kf315.net/",
    //扶贫预览
    previewPorvertyH5: "http://povertyh5.kf315.net",
    // 码链接前缀
    codeLinkPrefix: "http://code.kf315.net/u/14/",
    //沛县二维码
    // peixianQrCode: `${host}:3009`,
    peixianQrCode: "https://peixian-h5.p.kf315.net/",
    //岚翠会员商城域名
    lancuiShopH5Domain: "http://lcshop-h5.p.kf315.net",
  };
})();
