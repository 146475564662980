/*
 * @Author: duyaoyao
 * @Date: 2020-06-10 12:25:05
 * @LastEditors: duyaoyao
 * @LastEditTime: 2020-10-29 17:42:41
 */
import React from 'react';
import { Modal } from 'antd';

class CjmModal extends React.Component {
  state = { visible: false, confirmLoading: false, maskClosable: false };

  static success = options => {
    Modal.success(options);
  };

  show = () => {
    return new Promise(resolve => {
      const { maskClosable = false } = this.props;
      this.setState({ visible: true, maskClosable }, resolve);
    });
  };

  hide = () => {
    return new Promise(resolve => {
      this.setState({ visible: false,confirmLoading: false }, resolve);
      const cancelHandler = this.props.cancelHandler || null;
      if (cancelHandler) {
        cancelHandler();
      }
    });
  };

  okButtonClick = () => {
    this.setState({ confirmLoading: true });
    const { okHandler } = this.props;
    okHandler(() => {
      this.setState({ confirmLoading: false });
    });
  };

  render() {
    const { visible, confirmLoading, maskClosable } = this.state;
    const { notHide, cancelHandler } = this.props;
    return (
      <Modal
        {...this.props}
        onOk={this.okButtonClick}
        visible={visible}
        maskClosable={maskClosable}
        onCancel={notHide ? cancelHandler : this.hide}
        confirmLoading={confirmLoading}
        destroyOnClose
      />
    );
  }
}
CjmModal.success = Modal.success;
CjmModal.info = Modal.info;
CjmModal.error = Modal.error;
CjmModal.warning = Modal.warning;
CjmModal.confirm = Modal.confirm;

export default CjmModal;
