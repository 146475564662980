import React from 'react';
import {connect} from 'dva';
import CheckboxGroup from '../CheckboxGroup';
import Checkbox from '../Checkbox';
//
@connect()
export default class extends React.Component {
  render() {
    const {enums} = this.props;
    const enumsData = enums.getData();
    const names = Object.keys(enumsData);
    return (
      <CheckboxGroup {...this.props}>
        {names.map((name, index) => <Checkbox key={index} value={enumsData[name]}>{name}</Checkbox>)}
      </CheckboxGroup>
    )
  }
}
