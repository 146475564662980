/*
 * @Author: xutengfeng 
 * @Date: 2019-11-04 09:47:47 
 * @Last Modified by: xutengfeng
 * @Last Modified time: 2019-11-04 09:48:11
 */

import { Layout } from 'element-react';

import FormContainer from './FormContainer';

Layout.FormContainer = FormContainer;

export default Layout;