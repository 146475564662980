import React from 'react';
import {Upload, Modal} from 'antd';
import { Icon } from 'components';
import PropTypes from 'prop-types';
import config from 'framework/common/config';
import api from 'framework/common/api';
import classNames from 'classnames';

const {fileDomain} = config;
// eslint-disable-next-line class-methods-use-this
const dealFileList = (fileList) => {
  let formatImages = [];
  if (fileList) {
    if (fileList.constructor == String) {
      if (isUid(fileList)) {
        formatImages.push({uid: fileList, status: 'uploading', url: null, fileId: fileList});
      } else {
        const url = `${fileDomain}/${fileList}`;
        formatImages.push({uid: fileList, status: 'done', url, fileId: fileList});
      }
    } else {
      formatImages = fileList.map(item => {
        const url = `${fileDomain}/${item}`;
        return {uid: item, status: 'done', url, fileId: item};
      });
    }
  }
  return formatImages
}

const isUid = (str) => {
  return str.indexOf('rc-upload') === 0;
};

export default class CjmUpload extends React.PureComponent {

  static getDerivedStateFromProps(nextProps) {
    if ('value' in nextProps) {
      return {
        ...({fileList: dealFileList(nextProps.value)} || {}),
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    const {fileList} = props;
    const formatImages = dealFileList(fileList);
    this.state = {
      previewVisible: false,
      previewImage: '',
      name: '',
      fileList: formatImages,
    };
  }

  handleBeforeUpload = (file) => {
    const {name} = file;
    this.setState({name});
  };

  handleCancel = () => this.setState({previewVisible: false});

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  handleChange = ({file, fileList}) => {
    const {status} = file;
    const images = fileList.map((item) => {
      if (item.response && item.status == 'done' && item.response.msg == 'success') {
        return item.response.results;
      } else {
        return item.uid;
      }
    });
    this.setState({fileList: [...fileList]});
    this.triggerChange(images);
  };

  // 上传进度可以通过onProgress中的percent来控制样式，而不是file.status
  handleProgress = (event, file) => {
    let {fileList} = this.state;
    fileList = fileList.map(item => {
      if (item.uid === file.uid) {
        item.percent = event.percent;
      }
      return item;
    });
    this.setState({fileList});
  };
  valueClear = (value) => {
    if (Array.isArray(value)) {
      return value.map(valItem => this.valueClear(valItem)).filter(valItem => !!valItem);
    } else if (typeof value === 'string') {
      if (value.indexOf('rc-upload') >= 0) {
        return '';
      }
      return value;
    } else {
      return '';
    }
  };
  triggerChange = (images) => {
    const {onChange} = this.props;
    if (onChange) {
      let value = images.length == 1 ? images.toString() : images.filter((item) => item);
      value = this.valueClear(value);
      console.log({ value });
      onChange(value);
    }
  };

  render() {
    const {previewVisible, previewImage, fileList, name} = this.state;
    const {maxNumber, disabled = false, className} = this.props;
    const uploadButton = (
      <div>
        <Icon type="plus"/>
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <div className={classNames('clearfix', className)}>
        <Upload
          accept="image/*"
          action={api.FILE_UPLOAD}
          listType="picture-card"
          fileList={fileList}
          data={{name}}
          beforeUpload={this.handleBeforeUpload}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          onProgress={this.handleProgress}
          disabled={disabled}
        >
          {fileList.length >= maxNumber ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
          destroyOnClose
        >
          <img alt="example" style={{width: '100%'}} src={previewImage}/>
        </Modal>
      </div>
    );
  }
}
CjmUpload.prototypes = {
  maxNumber: PropTypes.number,
  images: PropTypes.array || PropTypes.string,
};
