export default {
  //
  domain: ".kf315.net",
  // 企业中心地址
  orgSite: "http://dev-enterprise.center.kf315.net",
  // 用户中心地址
  userCenterSite: "http://dev-user.center.kf315.net",
  // 用户中心登录页
  userCenterLogin: "http://dev-user.center.kf315.net/#/user/login",
  // 系统中心地址
  systemSite: "http://dev-system.center.kf315.net",
  // 系统中心  企业着落页地址
  systemLandingPageSite: `http://dev-system.center.kf315.net/#/platform/landingPage/exhibit`,
  // 平台中心地址
  platformSite: "http://dev-platform.center.kf315.net",
  // 新用户注册
  register: "http://dev-user.center.kf315.net/#/user/register",
  // 单点登录iframe的src
  ssoSrc: "http://dev-user.center.kf315.net/#/frame/user/login",
  // 图片上传返回值
  fileDomain: "http://filetest.cjm.so",
  // 视频文件域名
  videoDomain: "https://cjm3v.kf315.net",
  // 溯源预览，h5模板预览
  previewWarp: "http://dev-trace.h5.kf315.net",
  mapjs: "http://dev-gis.kf315.net",
  iotmapjs: "https://gis.jgwcjm.com",
  nurturePreview: "http://dev-nurtureh5.kf315.net",
  //贫困户预览
  previewPorverty: "http://dev-poverty.kf315.net",
  // 溯源扫码
  traceH5: "http://dev-trace.h5.kf315.net/#/traceH5",
  // 码营销H5
  integralMarketingH5Site: "http://dev-market.h5.kf315.net",
  //会员御花园
  integralShop: "https://dev-shop.h5.kf315.net",
  //政府对接
  govConnectSite: "http://dev-gov.connect.kf315.net",
  //iot平台
  iotSite: "http://dev-iot.kf315.net",
  //一体机
  aioSite: "http://dev-aio.h5.kf315.net",
  // 保险理赔H5
  insuranceDomain: "http://dev-insurance.h5.kf315.net/#/trace",
  //数据屏地址
  monitorScreenConfigPage: "http://localhost:8081",
  //润达数据屏预览地址
  monitorScreenConfigPageRunDa:
    "http://dev-freedom.h5.kf315.net/datascreen/runda/",
  monitorScreenConfigPageGugeng:
    "http://dev-freedom.h5.kf315.net/dataScreen/gugeng/",
  //微页配置工具地址
  microPageSite: "http://dev-designw.kf315.net",
  //H5工具地址
  h5ToolsSite: {
    home: "https://dev-h5toolsv3.kf315.net/h5Tools/home/index.html", //访问地址
    preview: "https://dev-h5toolsv3.kf315.net/h5Tools/preview/index.html", //预览地址
    settings: "https://dev-h5toolsv3.kf315.net/h5Tools/settings/index.html", //配置地址
    queryResults: {
      //H5工具-查询结果页-地址
      home: "https://dev-h5toolsv3.kf315.net/h5Tools/home/query-results.html",
      preview:
        "https://dev-h5toolsv3.kf315.net/h5Tools/preview/query-results.html",
      settings:
        "https://dev-h5toolsv3.kf315.net/h5Tools/settings/query-results.html",
    },
  },
  //防伪模板三链接
  antiHref3: "http://dev-antibackend.h5.kf315.net/u/r/h3",
  // 农安信用跳转链接
  creditHref: '',
  // 企业工具跳转链接
  mobileAppHref: "https://dev-test-mobile-website-h5.jgwcjm.com",
  //数字乡村一户一码
  digitalVillageHref: "http://dev-digitalVillage.kf315.net",
  // 防伪数据屏
  antiFakeDataScreen: "http://freedom.h5.kf315.net/datascreen/antiFake",
  // 基地监管GIS地图
  baseGISDataScreen: "http://freedom.h5.kf315.net/datascreen/kaihua",

  // 防伪
  antifakePlatform: "https://antifake.h5.kf315.net/",
  // b2b商城
  B2BShop: "https://b2b.kf315.net/",
  //扶贫预览
  previewPorvertyH5: "http://dev-povertyh5.kf315.net",
  // 码链接前缀
  codeLinkPrefix: "http://code.kf315.net/u/14/",
  //沛县二维码
  peixianQrCode: "https://peixian-h5.p.kf315.net/",
  //岚翠会员商城域名
  lancuiShopH5Domain: "http://dev-lcshop-h5.p.kf315.net",
};
