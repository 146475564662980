import React from "react";
import { connect } from "dva";
import PropTypes from "prop-types";
import { ajaxSync } from "framework/utils/ajax";
import Select from "../Select";
import Option from "../Option";
import styles from "./index.less";
import _ from "lodash";
import classNames from "classnames";

//
class RemoteSelect extends React.Component {
  state = { options: [], isIniting: false, renderSelect: true, loading: false };

  debounce = (func, wait) => {
    let timeout;
    return function() {
      let context = this;
      let args = arguments;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(context, args);
      }, wait);
    };
  };

  getValue = () => {
    const { value, mode } = this.props;
    if (mode === "multiple" && Array.isArray(value) && value.length > 0) {
      value.forEach((valItem, index) => {
        if (typeof valItem === "number") {
          value[index] = `${valItem}`;
        }
      });
      return value;
    }
    return typeof value === "number" ? `${value}` : value;
  };

  getAjaxParams = (query = "") => {
    const { queryKey, params = {} } = this.props;
    const defaultParams = { pageSize: 50, current: 1 };
    const _params = { ...defaultParams, ...params };
    _params[queryKey] = query;
    return _params;
  };
  handleSearch = async (query = "") => {
    const {
      remoteUrl,
      ajaxMethod = "get",
      allowEmptySearch = true,
      headers
    } = this.props;
    if (!allowEmptySearch && query == "") {
      return;
    }
    const params = this.getAjaxParams(query);
    this.setState({ loading: true });
    if(headers && Object.prototype.toString.call(ajaxSync.setHeaders)=== '[object Function]') {
      ajaxSync.setHeaders(headers);
    }
    const { err, res } = await ajaxSync[ajaxMethod](remoteUrl, params);
    this.setState({ loading: false });
    if (!err && res) {
      const results = this.getResList(res);
      this.setState({ options: results }, this.syncParentOptions);
    }
  };

  handleSelect = async () => {
    const { remoteUrl, ajaxMethod = "get", headers } = this.props;
    const params = this.getAjaxParams();
    if(headers && Object.prototype.toString.call(ajaxSync.setHeaders)=== '[object Function]') {
      ajaxSync.setHeaders(headers);
    }
    const { err, res } = await ajaxSync[ajaxMethod](remoteUrl, params);
    if (!err && res) {
      const results = this.getResList(res);
      this.setState({ options: results }, this.syncParentOptions);
    }
  };

  syncParentOptions = () => {
    const { onLoadComplate } = this.props;
    onLoadComplate && onLoadComplate(this.state.options);
  };

  getResList = (res) => {
    let list = [];
    const { getAllData } = this.props;
    if (Array.isArray(_.get(res, "results"))) {
      list = res.results;
    } else if (Array.isArray(_.get(res, "results.list"))) {
      list = res.results.list;
    }
    getAllData && getAllData(list);
    return list;
  };

  handleFocus = () => {
    const { options } = this.state;
    if (options.length === 0) {
      this.handleSearch();
    }
  };

  handleChange = (value) => {
    let { onChange, valueKey, blurOnChange, mode } = this.props;
    const activeOption = this.getActiveOption(value);
    if (typeof onChange === "function") {
      onChange(value, activeOption);
    } else if (typeof onChange === "object") {
      const options = this.getOptions();
      const option = options.find((opt) => opt[valueKey] == value);
      Object.keys(onChange).forEach((key) => {
        const onChangeKey = onChange[key];
        if (typeof onChangeKey === "function") {
          onChangeKey(option ? option[key] : "", activeOption);
        }
      });
    }
    setTimeout(() => {
      if (typeof blurOnChange === "undefined") {
        blurOnChange = mode !== "multiple";
      }
      const select = _.get(this, "refs.select.refs.select");
      blurOnChange && select && select.blur();
    }, 20);
  };

  setDefaultOptions = () => {
    const { label, labelKey, valueKey, mode } = this.props;
    const value = this.getValue();
    const options = [];
    if (mode === "multiple") {
      // 多选
      value.forEach((item, index) => {
        if (label[index]) {
          options.push({
            [valueKey]: item,
            [labelKey]: label[index],
          });
        }
      });
    } else {
      options.push({
        [valueKey]: value,
        [labelKey]: label,
      });
    }
    this.setState({ options });
  };

  async componentWillMount() {
    await this.init();
    const { getAllData } = this.props;
    getAllData && await this.handleSearch();
  }

  init = async () => {
    const { label } = this.props;
    const value = this.getValue();
    if (value) {
      if (label && label.length > 0) {
        this.setDefaultOptions();
        await this.handleSearch();
      } else {
        this.setState({ isIniting: true });
        await this.handleSearch();
        this.setState({ isIniting: false });
        this.setState({ renderSelect: false }, () =>
          this.setState({ renderSelect: true })
        );
      }
    }
  };

  async componentDidUpdate(prevProps) {
    if (prevProps.remoteUrl !== this.props.remoteUrl) {
      this.init();
      await this.handleSearch();
    }
  }

  getActiveOption = (_value) => {
    const { valueKey, mode } = this.props;
    const value = _value ? _value : this.getValue();
    const { options } = this.state;
    if (mode === "multiple") {
      // 多选
      return options.filter((opt) => value.includes(opt[valueKey]));
    } else {
      return options.find((opt) => value === opt[valueKey]);
    }
  };

  optionNoSame = (options) => {
    const results = [];
    const { valueKey } = this.props;
    options.forEach((item) => {
      if (!results.find((res) => res[valueKey] === item[valueKey])) {
        results.push(item);
      }
    });
    return results;
  };

  getOptions = () => {
    const { options } = this.state;
    const { label, valueKey, labelKey, mode } = this.props;
    const value = this.getValue();
    const results = this.optionNoSame([...options]);
    if (mode !== "multiple" && !results.find((item) => item[valueKey])) {
      if (label && value) {
        results.push({
          [labelKey]: label,
          [valueKey]: value,
        });
      }
    }
    return results;
  };
  
  checkDisabled = (opt) => {
    const { disableValues, valueKey,disableFilter } = this.props;
    const value = _.get(opt, valueKey, '');
    if (value && Array.isArray(disableValues) && disableValues.includes(value)) {
      return true;
    }
    if(value && disableFilter){
      return disableFilter(opt)
    }
    return false
  };

  render() {
    const remoteProps = {
      onSearch: this.debounce(this.handleSearch, 500),
      onFocus: this.handleFocus,
      showSearch: true,
      onSelect: this.handleSelect,
    };
    const { isIniting, renderSelect, loading } = this.state;
    // console.log(renderSelect);
    const options = this.getOptions();
    const props = { ...remoteProps, ...this.props };
    props.loading = loading;
    props.onChange = this.handleChange;
    const value = this.getValue();
    const { labelKey, valueKey, mode,customLable } = props;
    if (isIniting) {
      props.value = mode === "multiple" ? [] : "";
    }
    if (
      !props.suffixIcon &&
      props.className &&
      props.className.indexOf("cjm-remote-select") >= 0
    ) {
      props.className = classNames(props.className, "cjm-remote-icon-caret");
      props.suffixIcon = <i className="el-input__icon el-icon-caret-top"></i>;
    }
    const optionsComponents =
      options.length > 0 ? (
        options.map(
          (opt, index) =>
            opt && (
              <Option
                disabled={this.checkDisabled(opt)}
                key={`${opt[valueKey]}_${index}`}
                value={_.get(opt, valueKey, '')}
                label={opt[labelKey]}
              >
                  {customLable?customLable(opt):opt[labelKey]}
              </Option>
            )
        )
      ) : (
        <Option disabled value="">
          {props.emptyText}
        </Option>
      );
    return renderSelect ? (
      <Select ref="select" {...props}>
        {optionsComponents}
      </Select>
    ) : null;
  }
}

RemoteSelect.propTypes = {
  queryKey: PropTypes.string,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
};
RemoteSelect.defaultProps = {
  placeholder: "请选择",
  queryKey: "search",
  labelKey: "name",
  valueKey: "id",
  emptyText: "没有可选项",
  filterOption: false,
  allowClear: true,
  autoClearSearchValue: false,
};
export default RemoteSelect;
