import React from 'react';
import { DatePicker } from 'antd';
import './index.less';

export const AntiDatePicker = DatePicker;
export default class extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <DatePicker {...this.props}>{children}</DatePicker>
    );
  }
}
