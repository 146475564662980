import React from 'react';
import {Form, Col} from 'antd';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};
const advanceFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};
const btnFormItemLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 6 },
  },
};
class AdvanceFormItem extends React.Component {
  render() {
    const {colon, getFieldDecorator, name, children, label} = this.props;
    return (
      <Col xl={6} lg={8} md={10}>
        <FormItem colon={colon || false} {...advanceFormItemLayout} label={label}>
          {getFieldDecorator(name)(children)}
        </FormItem>
      </Col>
    )
  }
}
class NormalFormItem extends React.Component {
  render() {
    const {colon} = this.props;
    return (
      <FormItem colon={colon || false} {...formItemLayout} {...this.props} />
    )
  }
}
class BtnFormItem extends React.Component {
  render() {
    const {colon} = this.props;
    return (
      <FormItem colon={colon || false} {...btnFormItemLayout} {...this.props} />
    )
  }
}
export default {
  NormalFormItem,
  AdvanceFormItem,
  BtnFormItem,
}
