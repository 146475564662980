import {getConfig} from 'framework/siteConfig'
function formatter(menuData) {
  //暂时不作处理
  return menuData;
}
export const getMenuData = () => {
  const menuData = getConfig('menuData') || [];
  return formatter(menuData);
};

export const searchMenuItem = (path) => {
  const menuData = getMenuData();
  let matchMenuItem = null;
  menuData.forEach(menuItem => {
    if (!matchMenuItem) {
      matchMenuItem = menuItem.search(path);
    }
  });
  return matchMenuItem;
}
