/*
 * @Author: xutengfeng 
 * @Date: 2019-11-04 09:40:57 
 * @Last Modified by: xutengfeng
 * @Last Modified time: 2019-11-04 09:47:29
 */
import React from 'react';

import styles from './index.less';

export default ({ children , horizontal, maxWidth }) => (
  <div style={{ flexDirection: horizontal ? 'row' : undefined, maxWidth }} className={styles.container}>
    {children}
  </div>
);