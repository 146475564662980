import React from 'react';
import PropTypes from 'prop-types';
import {Input} from 'antd';
import { Icon } from 'components';

const Search = Input.Search;
const iconStyle = {
  position: 'absolute',
  right: '33px',
  top: '8px',
  color: 'rgba(169, 155, 155, 0.8)',
};
const searchStyle = {
  position: 'relative',
};

class BaseSearch extends React.Component {
  state = {showClose: false, value: '', placeholder: '请输入关键字搜索'};

  handleSearch = value => {
    const {search, onSearch} = this.props;
    if (search) {
      search(value);
    }
    if (onSearch) {
      onSearch(value);
    }
  };

  handleChange = e => {
    const value = e.target.value;
    if (value) {
      this.setState({showClose: true, value});
    } else {
      this.setState({showClose: false, value});
    }
  };

  reset = () => {
    this.setState({value: '', showClose: false});
    const {refresh} = this.props;
    if (refresh) {
      refresh();
    }
  };

  componentWillMount() {
    if (this.props.value) {
      const {value} = this.props;
      this.setState({value})
    }
  }

  render() {
    const {enterButton, placeholder = '请输入关键字搜索', style = {width: 250}} = this.props;
    const {showClose, value} = this.state;
    const searchProps = {...this.props};
    [
      'enterButton',
      'onSearch',
      'onChange',
      'value',
      'search',
      'refresh',
    ].forEach(key => {
      delete searchProps[key];
    });
    const closeIconStyle = {...iconStyle};
    if (enterButton) {
      closeIconStyle.right = '53px';
    }
    return (
      <div style={searchStyle}>
        <Search
          enterButton={enterButton}
          onSearch={this.handleSearch}
          onChange={this.handleChange}
          value={value}
          placeholder={placeholder}
          style={style}
          {...searchProps}
        />
        {showClose && <Icon type="close-circle" style={closeIconStyle} onClick={this.reset}/>}
      </div>
    );
  }
}

BaseSearch.propTypes = {
  enterButton: PropTypes.bool,
  handleSearch: PropTypes.func,
};
BaseSearch.defaultProps = {
  enterButton: false,
};
export default BaseSearch;
