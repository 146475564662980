import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { ajaxSync } from 'framework/utils/ajax';

class CjmTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        list: [],
        pagination: {
          current: 1,
          pageSize: 10,
          total: 10,
        },
      },
    };
  }

  async componentDidMount() {
    this.getTableData();
  }

  async getTableData(options) {
    const { remoteUrl } = this.props;
    try {
      if (remoteUrl) {
        const { err, res } = await ajaxSync.get(remoteUrl, options);
        if (err) {
          console.error(err);
        } else if (res) {
          const { list, page } = res.results;
          this.setState({
            data: {
              list,
              pagination: page,
            },
          });
        }
      }
    } catch (error) {
      // error处理
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.getTableData(pagination, filters, sorter);
  };

  render() {
    const { children, rowKey, ...rest } = this.props;
    const {
      data: { list, pagination },
    } = this.state;
    const paginationProps = {
      showSizeChanger: true,
      showQuickJumper: true,
      ...pagination,
    };
    // console.log({ rest })
    return (
      <Table
        bordered
        rowKey={rowKey || 'key'}
        dataSource={list}
        pagination={paginationProps}
        onChange={this.handleTableChange}
        {...rest}
        {...this.props}
      >
        {children}
      </Table>
    );
  }
}
CjmTable.propTypes = {
  remoteurl: PropTypes.string,
};
CjmTable.defaultProps = {
  remoteurl: '',
};
export default CjmTable;
