import React from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'antd';
import { Form, Icon } from 'components';
import styles from './index.less';

const Search = Input.Search;

class AdvanceSearch extends React.Component {
  state = { showAdvanced: false, placeholder: '请输入关键字搜索' };

  toggleForm = () => {
    const { showAdvanced } = this.state;
    const { onToggleForm } = this.props;
    onToggleForm && onToggleForm(!showAdvanced);
    this.setState({ showAdvanced: !showAdvanced });
  };

  handleSearch = (value) => {
    const { search } = this.props;
    if (search) {
      search(value);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { search, form } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        search(values);
      }
    });
  };

  handleReset = () => {
    const { form, search } = this.props;
    form.resetFields();
    search({});
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  render() {
    const { dropform, form } = this.props;

    const { showAdvanced, placeholder } = this.state;
    const dropformWithProps = dropform.props.children.map((item,index) => {
      return React.cloneElement(item, { ...form,key:index });
    });
    const searchProps = {...this.props};
    ['enterButton', 'onSearch', 'onChange', 'value', 'placeholder', 'style', 'search', 'refresh', 'onToggleForm'].forEach(key => {
      delete searchProps[key];
    });
    return (
      <div className={styles['ant-advanced-container']}>
        <div>
          {!showAdvanced && <Search
            enterButton
            style={{ width: 250 }}
            onSearch={this.handleSearch}
            placeholder={placeholder}
            {...searchProps}
          />}
          <a className="toggle-form-link" style={{ marginLeft: 8 }} onClick={this.toggleForm}>
            高级搜索 {showAdvanced ? (<Icon type="up"/>) : (<Icon type="down"/>)}
          </a>
        </div>
        {showAdvanced && (
          <div>
            <Form className={styles['ant-advanced-search-form']} onSubmit={this.handleSubmit}>
              {dropformWithProps}
              <div style={{ textAlign: 'right', marginTop: '30px' }}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                  重置
                </Button>
              </div>
            </Form>
          </div>
        )}
      </div>
    );
  }
}

AdvanceSearch.prototypes = {
  search: PropTypes.func,
};
export default Form.create()(AdvanceSearch);
