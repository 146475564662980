import React from 'react';
import {connect} from 'dva';
import classNames from 'classnames';
import {Icon} from 'components';
import styles from './index.less';
//
@connect()
export default class extends React.Component {
  state = {contentWidth: 0};

  componentDidMount() {
    const {width} = this.props;
    const contentWidth = width ? width : this.refs.stepGroup.offsetWidth;
    this.setState({contentWidth});
  }

  connectLineWidth = item => {
    const {data} = this.props;
    const {contentWidth} = this.state;
    const itemWidth = 51;
    const {length} = data;
    const width = (contentWidth - itemWidth * length) / (length - 1);
    if (width > 50) {
      return `${width}px`;
    }
    return '50px';
  };

  stepItem = item => {
    const {data, current} = this.props;
    const index = data.indexOf(item);
    const isLast = index === data.length - 1;
    const connectLineWidth = this.connectLineWidth(item);
    const connectLineStyle = {width: connectLineWidth};
    let type = 'normal';
    if (index < current) {
      type = 'pass';
    } else if (index == current) {
      type = 'current';
    }
    return (
      <div className={classNames(styles.stepItem, styles[type])} key={index}>
        <div className={styles.inner}>
          <div className={styles.round}>
            {type === 'pass' && <Icon className={styles.checkIcon} type="check"/>}
            {type !== 'pass' && <span>{index + 1}</span>}
          </div>
          <div className={styles.label}>{item.label}</div>
        </div>
        {isLast || <div style={connectLineStyle} className={styles.connectLine}/>}
      </div>
    );
  };

  stepGroup = () => {
    const {data, offsetLeft} = this.props;
    const style = {left: `${offsetLeft}px`};
    return (
      <div ref="stepGroup" style={style} className={styles.stepGroup}>
        {data.map(item => this.stepItem(item))}
      </div>
    );
  };

  render() {
    return this.stepGroup();
  }
}
