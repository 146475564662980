import React from 'react';
import {Upload} from 'antd';
//
export default class extends React.Component {
  render() {
    return (
      <div></div>
    )
  }
}
