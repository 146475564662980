import React from 'react';
import { Divider } from 'antd';

export default class extends React.Component {
    render() {
        const { type = 'horizontal', className = '' } = this.props;
        return (
            <Divider type={type} className={className} />
        );
    }
}
