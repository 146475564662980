import React from 'react';
import {Link} from 'dva/router';

//
export default class extends React.Component {
  handleClick = (event) => {
    const {onClick, href} = this.props;
    typeof onClick === 'function' && onClick(event);
    if (href === 'javascript:;') {
      event.preventDefault();
    }
  };
  getProps = () => {
    const props = {...this.props};
    if (props.href === 'javascript:;') {
      props.href = '';
    }
    props.onClick = this.handleClick;
    props.children = undefined;
    return props;
  };

  render() {
    const props = this.getProps();
    const Component = props.to ? Link : 'a';
    return (
      <Component {...props}>{this.props.children}</Component>
    );
  }
}
