import React from 'react';
import {Transfer} from 'antd';
export default class extends React.Component {
  render() {
    const {children} = this.props;
    return (
      <Transfer {...this.props}>{children}</Transfer>
    );
  }
}
