import React from 'react';
import {Select} from 'antd';
import './index.less';
import globalEventBus from 'framework/utils/eventBus';
import _ from 'lodash';


export default class extends React.Component {
  state = {
    disabled: false
  };

  componentDidMount() {
    globalEventBus.on('cjm-select-visible-change', this.handleCjmSelectVisibleChange);
  }

  componentWillUnmount() {
    globalEventBus.off('cjm-select-visible-change', this.handleCjmSelectVisibleChange);
  }

  handleCjmSelectVisibleChange = ({visible}) => {
    this.setState({disabled: true}, () => {
      this.setState({disabled: false});
    });
  };

  render() {
    const {disabled} = this.state;
    const {children} = this.props;
    const value = this.props.value === '' ? undefined : this.props.value;
    const finalProps = {...this.props, value};
    if (disabled) {
      finalProps.open = false;
    }
    return (
      <Select ref="select" {...finalProps}>{children}</Select>
    );
  }
}
