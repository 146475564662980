import React from 'react';
import PropTypes from 'prop-types';
import { ajaxSync } from 'framework/utils/ajax';
import { AutoComplete } from 'antd';
import styles from './index.less';
import _ from 'lodash';

const { Option } = AutoComplete;
//
export default class RemoteAutoComplete extends React.Component {
  state = { options: [], isIniting: false, renderSelect: true, loading: false, keydown: false };

  debounce = (func, wait) => {
    let timeout;
    return function() {
      const context = this;
      const args = arguments;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(context, args);
      }, wait);
    };
  };

  handleSearch = async (query = '') => {
    const { remoteUrl, queryKey } = this.props;
    let queryObj = queryKey ? { [queryKey]: query } : '';
    this.setState({ loading: true });
    const { err, res } = await ajaxSync.get(remoteUrl, queryObj);
    this.setState({ loading: false });
    if (!err && res) {
      const results = res.results.list || [];
      this.setState({ options: results });
      queryObj = { [queryKey]: '' };
    }
  };

  handleSelect = async () => {
    const { remoteUrl, queryKey } = this.props;
    const queryObj = queryKey ? { [queryKey]: '' } : '';
    const { err, res } = await ajaxSync.get(remoteUrl, queryObj);
    if (!err && res) {
      const results = res.results.list || [];
      this.setState({ options: results });
    }
  };

  handleFocus = () => {
    const { options } = this.state;
    if (options.length === 0) {
      this.handleSearch();
    }
    // this.handleSearch();
  };

  handleChange = value => {
    const { onChange, valueKey } = this.props;
    const activeOption = this.getActiveOption(value);
    if (typeof onChange === 'function') {
      onChange(value, activeOption);
    } else if (typeof onChange === 'object') {
      const options = this.getOptions();
      const option = options.find(opt => opt[valueKey] == value);
      Object.keys(onChange).forEach(key => {
        const onChangeKey = onChange[key];
        if (typeof onChangeKey === 'function') {
          onChangeKey(option[key], activeOption);
        }
      });
    }
  };

  setDefaultOptions = () => {
    const { value, label, labelKey, valueKey, mode } = this.props;
    const options = [];
    if (mode === 'multiple') {
      // 多选
      value.forEach((item, index) => {
        if (label[index]) {
          options.push({
            [valueKey]: item,
            [labelKey]: label[index],
          });
        }
      });
    } else {
      options.push({
        [valueKey]: value,
        [labelKey]: label,
      });
    }
    this.setState({ options });
  };

  async componentWillMount() {
    await this.init();
  }

  init = async () => {
    const { value, label } = this.props;
    if (value) {
      if (label && label.length > 0) {
        this.setDefaultOptions();
        await this.handleSearch();
      } else {
        this.setState({ isIniting: true });
        await this.handleSearch();
        this.setState({ isIniting: false });
        this.setState({ renderSelect: false }, () => this.setState({ renderSelect: true }));
      }
    }
  };

  async componentDidUpdate(prevProps) {
    if (prevProps.remoteUrl !== this.props.remoteUrl) {
      this.init();
      await this.handleSearch();
    }
  }

  getActiveOption = _value => {
    const { valueKey, mode } = this.props;
    const value = _value || this.props.value;
    const { options } = this.state;
    if (mode === 'multiple') {
      // 多选
      return options.filter(opt => value.includes(opt[valueKey]));
    } else {
      return options.find(opt => value == opt[valueKey]);
    }
  };

  optionNoSame = options => {
    const results = [];
    const { valueKey } = this.props;
    options.forEach(item => {
      if (!results.find(res => res[valueKey] === item[valueKey])) {
        results.push(item);
      }
    });
    return results;
  };

  getOptions = () => {
    const { options } = this.state;
    const { value, label, valueKey, labelKey, mode } = this.props;
    const results = this.optionNoSame([...options]);
    if (mode !== 'multiple' && !results.find(item => item[valueKey])) {
      if (label && value) {
        results.push({
          [labelKey]: label,
          [valueKey]: value,
        });
      }
    }
    return results;
  };

  render() {
    const remoteProps = {
      onSearch: this.handleSearch,
      onFocus: this.handleFocus,
      showSearch: true,
      onSelect: this.handleSelect,
    };
    const { isIniting, renderSelect, loading } = this.state;
    // console.log(renderSelect);
    const options = this.getOptions();
    const props = { ...remoteProps, ...this.props };
    props.loading = loading;
    props.onChange = this.handleChange;
    const { labelKey, valueKey, mode } = props;
    if (isIniting) {
      props.value = mode === 'multiple' ? [] : '';
    }
    const style = { color: '#bfcbd9' };
    const optionsComponents =
      options.length > 0 ? (
        options.map((opt, index) => (
          <Option key={`${opt[valueKey]}_${index}`} value={opt[valueKey].toString()}>
            {opt[labelKey]}
          </Option>
        ))
      ) : null;
    return renderSelect ? (
      <div className={styles.selectWrap}>
        <AutoComplete {...props}>{optionsComponents}</AutoComplete>
      </div>
    ) : null;
  }
}
RemoteAutoComplete.propTypes = {
  queryKey: PropTypes.string,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  placeholder: PropTypes.string,
  emptyText: PropTypes.string,
  filterOption: PropTypes.bool,
  allowClear: PropTypes.bool,
  autoClearSearchValue: PropTypes.bool,
};
RemoteAutoComplete.defaultProps = {
  placeholder: '请选择',
  queryKey: 'search',
  labelKey: 'name',
  valueKey: 'id',
  emptyText: '没有可选项',
  filterOption: false,
  allowClear: true,
  autoClearSearchValue: false,
};
