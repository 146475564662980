//注释:龙山县数据上报系统  指定区域选择
import React from "react";
import { Cascader } from "antd";
import { getProvinces, getChildAreas } from "framework/services/region";

class AppointRegionSelect extends React.Component {
  state = { options: [], value: [] };

  loadData = async selectedOptions => {
    console.log(selectedOptions)
    const targetOption = selectedOptions[selectedOptions.length - 1];
    if (!targetOption) {
      return;
    }
    const targetLevel = selectedOptions.length;
    console.log(targetLevel)
    const { value: valueKey, children: childrenKey } = this.props.fieldNames;
    targetOption.loading = true;
    const targetValue = targetOption[valueKey];
    const childAreas = await getChildAreas(targetValue);
    console.log(childAreas, targetValue)
    const { options } = this.state;
    targetOption.loading = false;
    if (childAreas.length === 0) {
      targetOption.isLeaf = true;
    } else {
      const { maxCols = 4 } = this.props;
      targetOption[childrenKey] = childAreas.map(item => ({
        ...item,
        isLeaf: targetLevel >= maxCols - 1
      }));
    }
    console.log([...options])
    this.setState({ options: [...options] });
  };

  handleChange = (value, selectedOptions) => {
    const { onChange } = this.props;
    this.setState({ value });
    onChange && onChange({ value, selectedOptions });
  };
  clearValue = () => {
    this.setState({ value: '' });
  };

  getOptionByValue = value => {
    const { options } = this.state;
    // levelArr需要显示的层级  // 0：省，1：市：2：区，3：街道， 4：社区/村
    const { levelArr } = this.props;
    const { value: valueKey, children: childrenKey } = this.props.fieldNames;
    const province = `${value.substr(0, 2)}0000`;
    const city = `${value.substr(0, 4)}00`;
    const district = value.substr(0, 6);
    const street = `${value.substr(0, 9)}000`;
    const village = value.substr(0, 12);
    if (/^\d{2}0000$/.test(value)) {
      // 省
      return options.find(opt => opt[valueKey] === value);
    } else if (/^\d{4}00$/.test(value)) {
      // 市
      if(levelArr[0] == 0){ // 只显示省，市
        return options
        .find(opt => opt[valueKey] === province)
        [childrenKey].find(opt => opt[valueKey] === value);
      } else if(levelArr[0] == 1){ // 只显示市
        return options
        .find(opt => opt[valueKey] === value);
      }
    } else if (/^\d{6}$/.test(value)) {
      // 区
      if(levelArr[0] == 0){ // 只显示省，市，区
        return options
        .find(opt => opt[valueKey] === province)
        [childrenKey].find(opt => opt[valueKey] === city)
        [childrenKey].find(opt => opt[valueKey] === value);
      } else if(levelArr[0] == 1){ // 只显示市，区
        console.log(options)
        return options
        .find(opt => opt[valueKey] === city)
        [childrenKey].find(opt => opt[valueKey] === value);
      } else if(levelArr[0] == 2){ // 只显示区
        return options
        .find(opt => opt[valueKey] === value);
      }
    } else if (/^\d{9}000$/.test(value)) {
      if(levelArr[0] == 0){ // 只显示省，市，区，街道
        return options
        .find(opt => opt[valueKey] === province)
        [childrenKey].find(opt => opt[valueKey] === city)
        [childrenKey].find(opt => opt[valueKey] === district)
        [childrenKey].find(opt => opt[valueKey] === value);
      } else if(levelArr[0] == 1){ // 只显示市，区，街道
        return options
        .find(opt => opt[valueKey] === city)
        [childrenKey].find(opt => opt[valueKey] === district)
        [childrenKey].find(opt => opt[valueKey] === value);
      } else if(levelArr[0] == 2){ // 只显示区，街道
        return options
        .find(opt => opt[valueKey] === district)
        [childrenKey].find(opt => opt[valueKey] === value);
      } else if(levelArr[0] == 3){ // 只显示街道
        return options
        .find(opt => opt[valueKey] === value)
      }
    } else if (/^\d{12}$/.test(value)) {
      // 村
      if(levelArr[0] == 0){ // 只显示省，市，区，街道,社区/村
        return options
        .find(opt => opt[valueKey] === province)
        [childrenKey].find(opt => opt[valueKey] === city)
        [childrenKey].find(opt => opt[valueKey] === district)
        [childrenKey].find(opt => opt[valueKey] === street)
        [childrenKey].find(opt => opt[valueKey] === value);
      } else if(levelArr[0] == 1){ // 只显示市，区，街道,社区/村
        return options
        .find(opt => opt[valueKey] === city)
        [childrenKey].find(opt => opt[valueKey] === district)
        [childrenKey].find(opt => opt[valueKey] === street)
        [childrenKey].find(opt => opt[valueKey] === value);
      } else if(levelArr[0] == 2){ // 只显示区，街道,社区/村
        return options
        .find(opt => opt[valueKey] === district)
        [childrenKey].find(opt => opt[valueKey] === street)
        [childrenKey].find(opt => opt[valueKey] === value);
      } else if(levelArr[0] == 3){ // 只显示街道,社区/村
        return options
        .find(opt => opt[valueKey] === street)
        [childrenKey].find(opt => opt[valueKey] === value);
      } else if(levelArr[0] == 4){ // 只显示社区/村
        return options.find(opt => opt[valueKey] === value);
      }
    }
  };

  init = async () => {
    const { getOptionByValue, loadData } = this;
    // levelArr需要显示的层级  // 0：省，1：市：2：区，3：街道， 4：社区/村
    const { initvalue, levelArr } = this.props;
    const province = `${initvalue.substr(0, 2)}0000`;
    const city = `${initvalue.substr(0, 4)}00`;
    const district = initvalue.substr(0, 6);
    const street = `${initvalue.substr(0, 9)}000`;
    const village = initvalue.substr(0, 12);
    if (initvalue && (initvalue.length === 6 || initvalue.length === 12)) {
      const value = [];
      if (/^\d{2}0000$/.test(initvalue)) {
        // 省
        value.push(initvalue);
      } else if (/^\d{4}00$/.test(initvalue)) {
        // 市
        if(levelArr[0] == 0){ // 省
          await loadData([getOptionByValue(province)]);
          value.push(province);
          value.push(initvalue);
        } else if(levelArr[0] == 1){ // 市
          value.push(initvalue);
        }
      } else if (/^\d{6}$/.test(initvalue)) {
        // 区
        if(levelArr[0] == 0){ // 省
          await loadData([getOptionByValue(province)]);
          await loadData([getOptionByValue(province), getOptionByValue(city)]);
          value.push(province);
          value.push(city);
          value.push(initvalue);
        } else if(levelArr[0] == 1){ // 市
          await loadData([
            getOptionByValue(city)
          ]);
          value.push(city);
          value.push(initvalue);
        } else if(levelArr[0] == 2){ // 区
          value.push(initvalue);
        }
      } else if (/^\d{9}000$/.test(initvalue)) {
        // 街道
        if(levelArr[0] == 0){ // 省
          await loadData([getOptionByValue(province)]);
          await loadData([getOptionByValue(province), getOptionByValue(city)]);
          await loadData([
            getOptionByValue(province),
            getOptionByValue(city),
            getOptionByValue(district)
          ]);
          value.push(province);
          value.push(city);
          value.push(district);
          value.push(initvalue);
        } else if(levelArr[0] == 1){ // 市
          await loadData([
            getOptionByValue(city)
          ]);
          await loadData([
            getOptionByValue(city),
            getOptionByValue(district)
          ]);
          value.push(city);
          value.push(district);
          value.push(initvalue);
        } else if(levelArr[0] == 2){ // 区
          await loadData([
            getOptionByValue(district)
          ]);
          value.push(district);
          value.push(initvalue);
        } else if(levelArr[0] == 3){ // 街道
          value.push(initvalue);
        }
      } else if (/^\d{12}$/.test(initvalue)) {
        // 村
        if(levelArr[0] == 0){ // 省
          await loadData([getOptionByValue(province)]);
          await loadData([getOptionByValue(province), getOptionByValue(city)]);
          await loadData([
            getOptionByValue(province),
            getOptionByValue(city),
            getOptionByValue(district)
          ]);
          await loadData([
            getOptionByValue(province),
            getOptionByValue(city),
            getOptionByValue(district),
            getOptionByValue(street)
          ]);
          value.push(province);
          value.push(city);
          value.push(district);
          value.push(street);
          value.push(initvalue);
        } else if(levelArr[0] == 1){ // 市
          await loadData([
            getOptionByValue(city)
          ]);
          await loadData([
            getOptionByValue(city),
            getOptionByValue(district)
          ]);
          await loadData([
            getOptionByValue(city),
            getOptionByValue(district),
            getOptionByValue(street)
          ]);
          value.push(city);
          value.push(district);
          value.push(street);
          value.push(initvalue);
        } else if(levelArr[0] == 2){ // 区
          await loadData([
            getOptionByValue(district)
          ]);
          await loadData([
            getOptionByValue(district),
            getOptionByValue(street)
          ]);
          value.push(district);
          value.push(street);
          value.push(initvalue);
        } else if(levelArr[0] == 3){ // 街道
          await loadData([
            getOptionByValue(street)
          ]);
          value.push(street);
          value.push(initvalue);
        } else if(levelArr[0] == 4){ // 社区/村
          value.push(initvalue);
        }
      }
      this.setState({ value });
    }
  };

  async componentDidMount() {
    let provinces=null;
    if(this.props.provincesCode){
      provinces = await getChildAreas(this.props.provincesCode);
    }else{
      provinces = await getProvinces();
    }
    // const provinces = await getProvinces();
    this.setState({
      options: provinces.map(item => ({ ...item, isLeaf: false }))
    });
    console.log(provinces.map(item => ({ ...item, isLeaf: false })))
    this.init();
  }

  findItem(value, options, key) {
    let item;
    for (let i = 0, nLen = options.length; i < nLen; i++) {
      item = options[i];
      if (item[key] == value) {
        return item;
      }
    }
    return;
  }

  getReadonlyDOM(options, value, fieldNames) {
    let items = options;
    let item;
    let title = "";
    for (let i = 0, nLen = value.length; i < nLen; i++) {
      item = this.findItem(value[i], items, fieldNames.value);
      if (item) {
        if (title.length > 0) {
          title += " / " + item[fieldNames.label];
        } else {
          title += item[fieldNames.label];
        }
        items = item[fieldNames.children];
      } else {
        break;
      }
    }
    return <span>{title}</span>;
  }

  render() {
    const { options, value } = this.state;
    const { placeholder, fieldNames, readOnly } = this.props;
    if (readOnly) {
      if (options && options.length > 0 && value && value.length > 0) {
        return this.getReadonlyDOM(options, value, fieldNames);
      }
    }
    const finalProps = { ...this.props };
    delete finalProps.maxCols;
    console.log(value)
    return (
      <Cascader
        {...finalProps}
        value={value}
        options={options}
        fieldNames={fieldNames}
        loadData={this.loadData}
        onChange={this.handleChange}
        changeOnSelect
        placeholder={placeholder}
      />
    );
  }
}

AppointRegionSelect.defaultProps = {
  placeholder: "请选择",
  fieldNames: { label: "cityName", value: "areaCode", children: "children" }
};
export default AppointRegionSelect;
