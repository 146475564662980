import React from 'react';
import { connect } from 'dva';

//
@connect()
export class SetMenu extends React.Component {
  constructor(props) {
    super(props);
    this.resetMenuData();
  }

  resetMenuData = () => {
    const { menuData, dispatch } = this.props;
    dispatch({ type: 'menu/resetMenuData', payload: { menuData } });
  }

  render() {
    return null;
  }
}

//
export default class MenuItem {
  menu = '';
  icon = '';
  path = '';
  children = [];
  parent = null;

  constructor(name) {
    this.set({ name });
  }

  set(setting) {
    Object.keys(setting).forEach(key => {
      this[key] = setting[key];
    });
    return this
  }

  append(menuItem) {
    menuItem.set({ parent: this });
    if (!this.children.includes(menuItem)) {
      this.children.push(menuItem);
    }
    return this
  }

  search(path) {
    if (this.path === path) {
      return this;
    } else {
      const children = this.deepChildren();
      const childMatch = children.find(child => child.path === path);
      return childMatch;
    }
  }

  deepChildren() {
    let result = [...this.children];
    this.children.forEach(child => {
      if (child) {
        const deep = child.deepChildren();
        result = result.concat(deep);
      }
    });
    return result;
  }
}

