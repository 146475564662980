import React from 'react';
//
export default class extends React.Component {
  getWrapProps = () => {
    const props = {...this.props};
    delete props['v-if'];
    delete props['v-show'];
    return props;
  }

  render() {
    const {children} = this.props;
    const vif = this.props['v-if'];
    const vshow = this.props['v-show'];
    if (vif !== undefined && !vif) {
      return null
    }
    const style = {};
    if (vshow !== undefined && !vshow) {
      style.display = 'none';
    }
    return <div {...this.getWrapProps()} style={style}>{children}</div>;
  }
}
